import styled from 'styled-components';
import WithDirection from '../../../settings/withDirection';

const LogoStyleWrapper = styled.div`
    
    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }
`;

export default WithDirection(LogoStyleWrapper);