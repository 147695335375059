import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popover from '../../components/uielements/popover';
import userpic from '../../image/user.png';
import authAction from '../../redux/auth/actions';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { request } from '../../settings';
import { getToken } from '../../helpers/utility';

const { logout } = authAction;

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      id: getToken().get('id'),
      user: {},
    };
  }

  componentWillMount() {
    const AUTH_TOKEN = 'Bearer '+this.props.token;
    request.defaults.headers.common['Authorization'] = AUTH_TOKEN;
    this.getUser();
  }
  
  getUser = () => {
    request.get('admin/usuarios/'+this.state.id)
      .then(user => {
        this.setState({
          user: user.data,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }
  
  render() {
    const { admin, showConfiguracion } = this.props;
    let user = this.props.user?this.props.user:this.state.user;
    const handleConfiguracion = () => {
      this.handleVisibleChange();
      showConfiguracion();
    }

    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        { !admin &&
        <a className="isoDropdownLink" href="# " onClick={handleConfiguracion}>
          Mi Perfil
        </a>}
        <a className="isoDropdownLink" onClick={this.props.logout} href="# ">
          Cerrar Sesión
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={(user && user.url_imagen)?user.url_imagen:userpic} />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}
export default connect(
  state => ({
    token: state.Auth.idToken,
    user: state.App.user,
  }),
  { logout }
)(TopbarUser);
