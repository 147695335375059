const options = [
  {
    key: '',
    label: 'Principal',
    leftIcon: 'ion-android-clipboard',
  },
  {
    key: 'links',
    label: 'Links',
    leftIcon: 'ion-link',
  },
  {
    key: 'pagos',
    label: 'Mis Pagos',
    leftIcon: 'ion-cash',
  },
  {
    key: 'politicas',
    label: 'Politicas y Condiciones',
    leftIcon: 'ion-information-circled',
  },
];

const optionsAdmin = [
  {
    key: '',
    label: 'Principal',
    leftIcon: 'ion-android-clipboard',
  },
  {
    key: 'productos',
    label: 'Productos',
    leftIcon: 'ion-link',
  },
  {
    key: 'usuarios',
    label: 'Usuarios',
    leftIcon: 'ion-android-contacts',
  },
  {
    key: 'pagos',
    label: 'Pagos',
    leftIcon: 'ion-cash',
  },
  {
    key: 'configuracion',
    label: 'Configuración',
    leftIcon: 'ion-android-settings',
  },
];

export { options, optionsAdmin };
