import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../image/logo-linketi.png';
import logoCorto from '../../image/logo-linketi-corto.png';
import LogoStyleWrapper from './styles/logo.style';

export default ({ collapsed, url }) => {
  const styleLogo = {
    width: 'auto',
    height: '80%',
  }
  return (
    <LogoStyleWrapper>
      <div className="isoLogoWrapper">
        <h3 className={collapsed?'visible':'hidden'} style={{height: '100%'}}>
          <Link to={'../'+url} style={{height: '100%'}}>
            <img alt="logo" src={logoCorto} style={styleLogo}/>
          </Link>
        </h3>
        <h3 className={!collapsed?'visible':'hidden'} style={{height: '100%'}}>
          <Link to={'../'+url} style={{height: '100%'}}>
            <img alt="logo" src={logo} style={styleLogo}/>
          </Link>
        </h3>
      </div>
    </LogoStyleWrapper>
  );
};
