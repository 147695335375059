import React, { Component } from 'react';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';
import { request, estadoSolicitud, paleta } from '../../settings/index';
import { getToken } from '../../helpers/utility';
import moment from 'moment';
import 'moment/locale/es';
import axios from 'axios';


const CancelToken = axios.CancelToken;
class TopbarNotification extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false,
      loading: false,
      notifications: [],
      id: getToken().get('id'),
      length: 0,
      interval: null,
      total: 0,
      reiniciar: false,
    };
    this.gets = [];
    this.params = {
      usuario: !this.props.admin?this.state.id:null,
      sort_key: !this.props.admin?'fecha_pago':'fecha_solicitud',
      sort_order: 'descend',
      procesados: !this.props.admin?false:true,
      page: 1,
    };
  }

  componentWillMount() {
    const AUTH_TOKEN = 'Bearer '+this.props.token;
    request.defaults.headers.common['Authorization'] = AUTH_TOKEN;
    this.getSolicitudes(this.params);
    let interval = setInterval(()=>{
      if(!this.state.visible){
        this.getSolicitudes(this.params);
      }
    },50000);
    this.setState({interval: interval});
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }
  estado = (status) => {
    switch(status) {
        case estadoSolicitud.pagado:
        return 'Realizado';
        case estadoSolicitud.pagado2:
        return 'Realizado';
        case estadoSolicitud.rechazado:
        return 'Rechazado';
        default:
        return 'Rechazado';
    }
  }

  isNuevo = (status) => {
    if(status === estadoSolicitud.pagado || status === estadoSolicitud.rechazado || status === estadoSolicitud.proceso){
      return true;
    }
    return false;
  }

  solicitudes = (data) => {
    const sols = data.filter(d => {
    if(this.props.admin){
      return d.status === estadoSolicitud.proceso || d.status === estadoSolicitud.proceso2;
    }
    return d.status !== estadoSolicitud.proceso && d.status !== estadoSolicitud.proceso2;
    });
    return sols.map(
        (d, i) => {
            return {
                key: i,
                estado: this.estado(d.status),
                fecha_solicitud: moment(d.fecha_hora_solicitud).format("DD/MM/YYYY"),
                fecha_pago: moment(d.fecha_hora_procesado).format("DD/MM/YYYY"),
                ...d,
            } 
        });  
  }

  getSolicitudes = (params= {}) => {
    this.setState({loading: true});
    if(this.gets.length!==0){
      this.gets.forEach(g => {
          g();
      });
      this.gets = [];
    }

    request.get('admin/pagos/solicitudes',{
      params: params,
      cancelToken: new CancelToken((c) => {
          this.gets.push(c);
      }),
    }).then(response => {
        const solicitudes = this.solicitudes(response.data.data);
        let length = 0;
        let notifications = this.state.notifications;   
        if(this.state.reiniciar) {
          notifications = [];
          this.setState({reiniciar: false});
        }
        notifications = notifications.concat(solicitudes.map((solicitud, i) => {
          const nuevo = this.isNuevo(solicitud.status);
          if(nuevo) {
            length++;
          }
          let notification = 'Monto: '+solicitud.monto+'$ Fecha de solicitud: '+solicitud.fecha_solicitud;
          if(!this.props.admin) {
            notification = 'Monto: '+solicitud.monto+'$ Fecha: '+solicitud.fecha_pago;
          }
          return {
            id: solicitud.id,
            name: this.props.admin?'Solicitud de pago':'Pago '+solicitud.estado,
            notification: notification,
            nuevo: nuevo,
            status: solicitud.status,
            fecha_hora_procesado: moment(solicitud.fecha_hora_procesado),
          }
        }));
        this.setState({
            notifications: notifications,
            length: length,
            loading: false,
            total: response.data.total,
        });
    }).catch(error => {
      console.log(error);
      this.setState({loading: false});
    });
  }

  put = (pago) => {
    this.setState({loading: true});
    request.put('admin/pagos/solicitudes/'+pago.id, pago)
        .then(response => {
            this.setState({
                loading: false,
            });
        })
        .catch(error => {
            console.log(error);
            this.setState({
                loading: false,
            });
        });
  }

  hide() {
    this.setState({ visible: false });
  }

  actualizarUser = () => {

  }

  handleVisibleChange() {
    if(this.state.visible === true){
      const notifications = this.state.notifications.map(n => {
        if(n.nuevo){
          let status = estadoSolicitud.pagado2;
          if(n.status === estadoSolicitud.rechazado) {
            status = estadoSolicitud.rechazado2;
          }
          if(this.props.admin){
            status = estadoSolicitud.proceso2;
          }
          this.put({
          id: n.id, 
          status: status, 
          fecha_hora_procesado: n.fecha_hora_procesado,
          });
          n.nuevo = false;
        }
        return {...n};
      });
      this.params.page = 1;
      this.setState({
        reiniciar: true,
        notifications: notifications,
      });
    }
    this.setState({ 
      visible: !this.state.visible,
      length: 0,
     });
  }

  handleVerMas = (e) => {
    e.preventDefault();
    this.params.page++;
    this.getSolicitudes(this.params);
  }

  render() {
    const { notifications, total} = this.state;
    const length = (this.state.length>3)?'3+':this.state.length;
    const content = (
      <TopbarDropdownWrapper className="topbarNotification">
        <div className="isoDropdownHeader">
          <h3>
            <IntlMessages id="sidebar.notification" />
          </h3>
        </div>
        <div className="isoDropdownBody">
          {notifications.map(notification => (
            <div className={"isoDropdownListItem"+(!notification.nuevo?' viejo':'')}key={notification.id} >
              <h5>{notification.name}</h5>
              <p>{notification.notification}</p>
            </div>
          ))}
          {(notifications.length===0) &&
          <div className="sinNotificaciones">
            <h5>No posees notificaciones de pago</h5>
          </div>
          }
          {(notifications.length<total) &&
          <div className="verMasWrapper">
            <a className="isoViewAllBtn" href="# " onClick={this.handleVerMas}>
              Ver más
            </a>
          </div>
          }
        </div>
      </TopbarDropdownWrapper>
    );
    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div className="isoIconWrapper">
          <i
            className="ion-android-notifications"
            style={{color: paleta.blueDark}}
          />
          {(length!==0) &&
          <span>{length}</span>
          }
        </div>
      </Popover>
    );
  }
}

export default connect(state => ({
  ...state.App,
  token: state.Auth.idToken,
}))(TopbarNotification);
