import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import Sidebar from '../Sidebar/Sidebar';
import Topbar from '../Topbar/Topbar';
import AppRouter from './AppRouter';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import AppHolder from './commonStyle';
import logo from '../../image/logo-linketi-fondo-blanco.png';
import Modal from '../../components/modal/modal';
import message from "../../components/feedback/message";
import { Redirect } from 'react-router-dom';
import MessageContent from "../../components/feedback/messageContent.style";
import Configuracion from '../Configuracion/configuracion';
import { request } from '../../settings/index';
import { getToken } from '../../helpers/utility';
import './global.css';

const { Content, Footer } = Layout;
const { logout } = authAction;
const { toggleAll, setUser } = appActions;
export class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showConfiguracion: false,
      closingConfiguracion: false,
      loadingConfiguracion: false,
      id: getToken().get('id'),
      isAdmin: getToken().get('isAdmin'),
    }
  }

  success = (text) => {
    message.success(
        <MessageContent>
           {text}
        </MessageContent>,
        2
    );
  }

  error = (mensaje) => {
    const msj = mensaje || 'Ocurrio un error';
    message.error(
        <MessageContent>
           {msj}
        </MessageContent>,
        2
    );
  }

  showConfiguracion = () => {
    this.setState((state) => ({ showConfiguracion: !state.showConfiguracion }));
  }

  closeConfiguracion = () => {
    this.setState({
      showConfiguracion: false,
      closingConfiguracion: true,
    });
    setTimeout(() => {
      this.setState({ 
        closingConfiguracion: false,
      });
    }, 500);
  }

  getUsuario = () => {
    request.get('admin/usuarios/'+this.state.id)
        .then(response => {
            const user = response.data;
            this.props.setUser(user);
        })
        .catch(error => {
            console.log(error);
        });
  }

  cambiarPaypal = (email_paypal) => {
    request.put('usuario/actualizacion/paypal',{email_paypal})
      .then(response => {
        if(response.data.success || response.data.email_paypal){
          this.getUsuario();
          this.success('Datos actualizados correctamente');
        }
        else{
          this.error('El medio de pago ya esta en uso');
        }
      })
      .catch(error => {
        console.log(error);
        this.error();
      });
  }

  handleConfiguracion = (user) => {
    this.setState({loadingConfiguracion: true});
    request.put('usuario/perfil/actualizacion', user)
      .then(response => {
        this.setState({
          loadingConfiguracion: false,
          showConfiguracion: false,
        });
        const data = response.data;
        if(data.success){
          this.cambiarPaypal(user.email_paypal);
        }
        else{
          this.error();
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loadingConfiguracion: false,
          showConfiguracion: false,
        });
        this.error();
      });
  }

  render() {
    const { url } = this.props.match;
    const { height } = this.props;
    const { isAdmin } = this.state;
    const admin = (this.props.match.path==='/admin')?true:false;
    if(isAdmin==='true' && !admin){
      return <Redirect to={{ pathname: '/admin'}} />;
    }
    if(isAdmin==='false' && admin){
      return <Redirect to={{ pathname: '/user'}} />;
    }
    const appHeight = window.innerHeight;
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            <Topbar url={url} admin={admin} showConfiguracion={this.showConfiguracion}/>
            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Sidebar url={url} admin={admin}/>
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: height
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    flexShrink: '0',
                    background: '#f1f3f6',
                    position: 'relative'
                  }}
                >
                  <div className="logoPrintWrapper">
                    <img alt="logo" src={logo}/>
                  </div>
                  <AppRouter url={url} admin={admin}/>
                  {
                  (this.state.showConfiguracion || this.state.closingConfiguracion) &&
                  <Modal
                  visible={this.state.showConfiguracion}
                  onCancel={this.closeConfiguracion}
                  footer={null}
                  >
                    <Configuracion 
                    handle={this.handleConfiguracion}
                    loading={this.state.loadingConfiguracion}
                    />
                  </Modal>
                  } 
                </Content>
                <Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'start',
                    borderTop: '1px solid #ededed',
                    color: 'rgba(0,0,0,0.65)'
                  }}
                >
                  <span className="nameWrapper"><span>Link</span>eti</span> - Todos los derechos reservados 2018
                </Footer>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

export default connect(
  state => ({
    auth: state.Auth,
    height: state.App.height
  }),
  { logout, toggleAll, setUser }
)(App);
