import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '',
    component: asyncComponent(() => import('../User/Principal/principal')),
  },
  {
    path: 'links',
    component: asyncComponent(() => import('../User/Links/links')),
  },
  {
    path: 'pagos',
    component: asyncComponent(() => import('../User/MisPagos/misPagos')),
  },
  {
    path: 'politicas',
    component: asyncComponent(() => import('../User/Politicas/politicas')),
  },
  {
    path: 'link/:id',
    component: asyncComponent(() => import('../User/Link/link')),
  },
  {
    path: 'producto',
    component: asyncComponent(() => import('../User/Link/link')),
  },
];

const routesAdmin = [
  {
    path: '',
    component: asyncComponent(() => import('../Admin/Principal/principal')),
  },
  {
    path: 'productos',
    component: asyncComponent(() => import('../Admin/Productos/productos')),
  },
  {
    path: 'usuarios',
    component: asyncComponent(() => import('../Admin/Usuarios/usuarios')),
  },
  {
    path: 'pagos',
    component: asyncComponent(() => import('../Admin/Pagos/pagos')),
  },
  {
    path: 'configuracion',
    component: asyncComponent(() => import('../Admin/Configuracion/configuracion')),
  },
  {
    path: 'producto',
    component: asyncComponent(() => import('../Admin/Producto/producto')),
  },
  {
    path: 'usuario',
    component: asyncComponent(() => import('../Admin/Usuario/usuario')),
  },
];

class AppRouter extends Component {
  render() {
    const { url, style, admin } = this.props;
    const rout = admin?routesAdmin:routes;
    return (
      <div style={style}>
        {rout.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;
          return (
            <Route
              exact={exact === false ? false : true}
              key={singleRoute.path}
              path={`${url}/${singleRoute.path}`}
              {...otherProps}
            />
          );
        })}
      </div>
    );
  }
}

export default AppRouter;
