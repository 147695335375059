import React, { Component } from 'react';
import ConfiguracionStyleWrapper from './configuracion.style';
import Button from '../../components/uielements/button';
import { Input, Spin, Icon } from 'antd';
import DatePicker from '../../components/uielements/datePicker';
import locale from 'antd/lib/date-picker/locale/es_ES';
import { LocaleProvider } from 'antd';
import Form from '../../components/uielements/form';
import InputUpload from '../../components/uielements/inputUpload';
import { getToken } from '../../helpers/utility';
import { connect } from 'react-redux';
import { request } from '../../settings';
import message from "../../components/feedback/message";
import MessageContent from "../../components/feedback/messageContent.style";
import moment from 'moment';
import 'moment/locale/es';

const FormItem = Form.Item;
const loadingIcon = <Icon type="loading" style={{ fontSize: 30 }} spin />

class Configuracion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fecha_nacimiento: null,
            nombres: null,
            apellidos: null,
            password: null, 
            faltaFecha: false,
            menor: false,
            id: getToken().get('id'),
            loading: false,
            isSocial: false,
            email_paypal: null,
        }
    }

    componentWillMount() {
        const AUTH_TOKEN = 'Bearer '+this.props.token;
        request.defaults.headers.common['Authorization'] = AUTH_TOKEN;
        this.getUsuario();
    }

    error = () => {
        message.error(
            <MessageContent>
               Ocurrio un error
            </MessageContent>,
            2
        );
    }

    getUsuario = () => {
        this.setState({loading: true});
        request.get('admin/usuarios/'+this.state.id)
            .then(response => {
                const user = response.data;
                this.setState({
                    fecha_nacimiento: user.fecha_nacimiento?moment(user.fecha_nacimiento):null,
                    nombres: user.nombres,
                    apellidos: user.apellidos,
                    password: user.password,
                    email_paypal: user.email_paypal,
                    loading: false,
                    isSocial: (user.is_social==='1')?true:false,
                });
            })
            .catch(error => {
                this.error();
                this.setState({loading: false});
            });
    }

    handleInput = (e, key) => {
        this.setState({
            [key]: e.target.value,
        });
    }

    handleDate = (value) => {
        this.setState({
            fecha_nacimiento: value,
            faltaFecha: false,
            menor: false,
        });
    }
    
    handleInputUpload = (imagen) => {
        this.setState({imagen: imagen});
    }

    render() {
        const { loading, handle } = this.props;
        const { 
            fecha_nacimiento, 
            faltaFecha,
            nombres,
            apellidos,
            password,
            menor,
            imagen,
            isSocial,
            email_paypal, 
        } = this.state;
        const { getFieldDecorator } = this.props.form;
    
        const handleSubmit = e => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
                if(!fecha_nacimiento){
                    this.setState({faltaFecha: true});
                }
                else{
                    if(moment().diff(fecha_nacimiento,'years') < 11){
                        this.setState({menor: true});
                    }
                    else{
                        if (!err) {

                            let usuarioNuevo = {
                                nombres,
                                apellidos,
                                fecha_nacimiento: moment(fecha_nacimiento).format('YYYY-MM-DD'),
                                email_paypal,
                            };

                            if(imagen){
                                usuarioNuevo.imagen = imagen;
                            }

                            if(password && password!==''){
                                usuarioNuevo.password = password;
                            } 

                            handle(usuarioNuevo);
                        }
                    } 
                }
            });
        }

        return (
        <Spin spinning={this.state.loading} indicator={loadingIcon}>
            <ConfiguracionStyleWrapper>
                <h2 className="title">Configuración</h2>
                <div className="isoConfiguracionForm">
                    <Form onSubmit={handleSubmit}>
                        <div className="isoInputWrapper">
                            <FormItem>
                                {getFieldDecorator('nombre', {
                                initialValue: nombres,
                                rules: [
                                    {
                                    required: true,
                                    message: 'Por favor ingresa sus nombres',
                                    },
                                ],
                                })(
                                <Input 
                                name='nombre'
                                id='nombre'
                                placeholder="Nombres"
                                onChange={(e) => this.handleInput(e, 'nombres')}
                                />)}
                            </FormItem>
                        </div>
                        <div className="isoInputWrapper">
                            <FormItem>
                                {getFieldDecorator('apellido', {
                                initialValue: apellidos,
                                rules: [
                                    {
                                    required: true,
                                    message: 'Por favor ingresa sus apellidos',
                                    },
                                ],
                                })(
                                <Input 
                                name='apellido'
                                id='apellido'
                                placeholder="Apellidos"
                                onChange={(e) => this.handleInput(e, 'apellidos')}
                                />)}
                            </FormItem>
                        </div>
                        <div className={'isoInputWrapper'+((faltaFecha || menor)?' falta':'')}>
                            <LocaleProvider locale={locale}>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    placeholder="Fecha de nacimiento"
                                    onChange={this.handleDate}
                                    showToday={false}
                                    locale={locale}
                                    value={fecha_nacimiento}
                                />
                            </LocaleProvider>
                            {faltaFecha && 
                            <div className="faltaFechaWrapper">
                                <p>Por favor ingresa su fecha de nacimiento</p>
                            </div>
                            }
                            {menor && 
                            <div className="faltaFechaWrapper">
                                <p>No posees la edad suficiente</p>
                            </div>
                            }
                        </div>
                        <div className="isoInputWrapper">
                            <FormItem>
                                {getFieldDecorator('email_paypal', {
                                initialValue: email_paypal,
                                rules: [
                                    {
                                    type: 'email',
                                    message: 'El email de PayPal no es valido',
                                    },
                                    {
                                    required: true,
                                    message: 'Por favor ingresa el email de PayPal',
                                    },
                                ],
                                })(
                                <Input 
                                name='email_paypal'
                                id='email_paypal'
                                placeholder="Email PayPal"
                                onChange={(e) => this.handleInput(e, 'email_paypal')}
                                />)}
                            </FormItem>
                        </div>
                        { !isSocial && 
                        <div className="isoInputWrapper">
                            <FormItem>
                                {getFieldDecorator('password', {
                                rules: [
                                    {
                                    min: 8,
                                    message: 'La nueva contraseña es muy corta',
                                    },
                                ],
                                })(
                                <Input 
                                name='password'
                                id='password'
                                type='password'
                                placeholder="Nueva contraseña"
                                onChange={(e) => this.handleInput(e, 'password')}
                                />)}
                            </FormItem>
                        </div>
                        }
                        <div className="isoInputWrapper">
                            <InputUpload 
                            label={'Cambiar imagen de perfil'}
                            handle={this.handleInputUpload}
                            />
                        </div>
                        <FormItem>
                            <Button
                            type="primary"
                            id="btnConfiguracion"
                            htmlType="submit"
                            loading={loading}
                            >
                               Actualizar
                            </Button>
                        </FormItem>
                    </Form>
                </div>
            </ConfiguracionStyleWrapper>
        </Spin>
        );
    }
}

const WrappedConfiguracion = Form.create()(Configuracion);

export default connect(
    state => ({
        token: state.Auth.idToken,
    }),
    {}
)(WrappedConfiguracion);