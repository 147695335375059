import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';

import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';

const RestrictedRoute = ({ component: Component, isLoggedIn, admin, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} admin={admin}/>
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location }
          }}
        />
      )
    }
  />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <div>
        <Route
          exact
          path={'/'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route
          exact
          path={'/signin'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route
          path={'/verificar/:hash'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route
          path={'/recuperar_contrasena/:hash_recuperar'}
          component={asyncComponent(() => import('./containers/Page/signin'))}
        />
        <Route
          path={'/link/:hash'}
          component={asyncComponent(() => import('./containers/Link/link'))}
        />
        <RestrictedRoute
          path="/user"
          component={App}
          isLoggedIn={isLoggedIn}
          admin={false}
        />
        <RestrictedRoute
          path="/admin"
          component={App}
          isLoggedIn={isLoggedIn}
          admin={true}
        />
      </div>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null
}))(PublicRoutes);
