import styled from "styled-components";
import { palette } from "styled-theme";
import { paleta } from "../../settings/index";

const AppHolder = styled.div`
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette("primary", 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette("secondary", 1)};
    @media print {
      height: 100% !important;
    }
    
    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;

      @media print {
        height: 100% !important;
      }

      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette("border", 0)};
    height: 100%;
  }

  .isoLayoutContentWrapper {
    overflow: visible;
    @media print {
      padding: 0px;
    }
  }

  .isomorphicContent {
    padding: 70px 0 0;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;

    @media print {
      padding: 0px;
      background-color: #fff !important;
    }
  }

  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
    }
  }

  .ant-layout-footer {
    font-size: 13px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }

    @media print {
      position: absolute;
      bottom: 0px;
      width: 100%;
    }
  }

  button {
    border-radius: 0;
  }

  .nameWrapper {
    font-weight: bold;
    color: ${paleta.green};

    span {
      color: ${paleta.orange};
    }
  }

  .logoPrintWrapper {
    display: none;
    margin-bottom: 30px;

    img {
      width: 150px;
    }

    @media print {
      display: block;
    }
  }

  .pasoUltimoWrapper {
        position: absolute;
        top: 90px;
        left: calc(50% - 125px);
        width: 250px;
  }
  
`;

export default AppHolder;
